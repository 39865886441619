import React from "react";
import SortableTable from "../../component/sortable-table/SortableTable";
import {getUTCDateAtFaceValue, prettyTimestampFromDate} from "../../helpers";

export default function SessionDataTable({filteredSessionData, parameters, selectedId, setSelectedId, setHoveringX, dataDescription}) {
    if (!filteredSessionData) return null;

    return (<SortableTable
        items={filteredSessionData}
        fields={parameters.map(param => {
            let unit = ''
            if (dataDescription && param.key in dataDescription) {
                unit = dataDescription[param.key].unit;
            }
            let title = param.title;
            if (title === 'Tryptophan') {
                title = 'Tryp.';
            } else if (title === 'Turbidity') {
                title = 'Turb.';
            } else if (title === 'Conductivity') {
                title = 'Conduc.';
            } else if (title === 'Temperature') {
                title = 'Temp.';
            }
            return {
                key: param.key,
                title: `${title} ${unit ? `(${unit})` : ''}`,
                metric: param.metric,
            }
        })}
        customRenderFunction={(item, idx) => {
            return (<tr key={item.id || idx}
                        onClick={_ => setSelectedId(item)}
                        id={`row-${item.id}`}
                        className={selectedId && item.id === selectedId.id ? 'selected' : ''}>
                {parameters.map(param => {
                    if (param.metric) {
                        const diffFromMean = item.properties[param.key] - param.metric.mean;
                        const minValue = param.metric.mean - param.metric.std_deviation * 2;
                        const maxValue = param.metric.mean + param.metric.std_deviation * 2;
                        const ratio = (diffFromMean - minValue) / (maxValue - minValue);

                        return <td key={param.key}
                                   onMouseEnter={_ => setHoveringX(item)}
                                   style={{
                                       background: `rgba(255, 0, 0, ${ratio})`,
                                       color: ratio > 0.7 ? 'white' : 'black'
                                   }}>
                            {item.properties[param.key]}
                        </td>
                    }
                    return <td key={param.key}
                               onMouseEnter={_ => setHoveringX(item)}>
                        {param.key === 'timestamp' ? prettyTimestampFromDate(getUTCDateAtFaceValue(item.properties.timestamp)) : item.properties[param.key]}
                    </td>
                })}
            </tr>)
        }}
    />)
}
import React, { useEffect, useMemo, useRef } from 'react';
import * as d3 from 'd3';

export default function MultiLineChart({
                                           id,
                                           dataSeries,  // Array of data series
                                           height = 400,
                                           width = 800,
                                       }) {
    const chartRef = useRef();

    const margin = { top: 10, right: 65, bottom: 30, left: 50 };
    const wantedWidth = width - margin.left - margin.right;
    const wantedHeight = height - margin.top - margin.bottom;

    // Compute the x-axis domain (common for all series)
    const xScale = useMemo(() => {
        const allDataPoints = dataSeries.flatMap(series => series.data);
        return d3
            .scaleTime()
            .domain(d3.extent(allDataPoints, (d) => d.timestamp))
            .range([0, width - margin.right - margin.left]);
    }, [dataSeries, width, margin.right, margin.left]);

    // Separate data series by y-axis position
    const leftSeries = dataSeries.filter(series => series.yAxisPosition === 'left' || !series.yAxisPosition);
    const rightSeries = dataSeries.filter(series => series.yAxisPosition === 'right');

    // Compute y-axis domains for left and right axes
    const leftYDomain = useMemo(() => {
        let minY = Infinity, maxY = -Infinity;
        leftSeries.forEach(series => {
            const [min, max] = d3.extent(series.data, d => d[series.yKey]);
            if (min < minY) minY = min;
            if (max > maxY) maxY = max;
        });
        return [minY, maxY];
    }, [leftSeries]);

    const rightYDomain = useMemo(() => {
        let minY = Infinity, maxY = -Infinity;
        rightSeries.forEach(series => {
            const [min, max] = d3.extent(series.data, d => d[series.yKey]);
            if (min < minY) minY = min;
            if (max > maxY) maxY = max;
        });
        return [minY, maxY];
    }, [rightSeries]);

    // Create y-axis scales
    const leftYScale = useMemo(() => {
        return d3.scaleLinear()
            .domain(leftYDomain)
            .range([wantedHeight, 0]);
    }, [leftYDomain, wantedHeight]);

    const rightYScale = useMemo(() => {
        return d3.scaleLinear()
            .domain(rightYDomain)
            .range([wantedHeight, 0]);
    }, [rightYDomain, wantedHeight]);

    useEffect(() => {
        // Remove any previous content
        d3.select(chartRef.current).selectChildren().remove();

        // Create the SVG element
        const svg = d3
            .select(chartRef.current)
            .append('svg')
            .attr('width', wantedWidth + margin.left + margin.right)
            .attr('height', wantedHeight + margin.top + margin.bottom)
            .append('g')
            .attr('transform', `translate(${margin.left},${margin.top})`);

        // Add the X axis
        svg.append('g')
            .attr('transform', `translate(0,${wantedHeight})`)
            .call(d3.axisBottom(xScale).tickFormat(d3.timeFormat('%H:%M')));

        // Add the Y axes
        if (leftSeries.length > 0) {
            svg.append('g')
                .attr('class', 'y-axis-left')
                .call(d3.axisLeft(leftYScale));

            // Add left y-axis label
            svg.append('text')
                .attr('transform', 'rotate(-90)')
                .attr('y', 0 - margin.left)
                .attr('x', 0 - (wantedHeight / 2))
                .attr('dy', '1em')
                .style('text-anchor', 'middle')
                .text(leftSeries[0].name);
        }

        if (rightSeries.length > 0) {
            svg.append('g')
                .attr('class', 'y-axis-right')
                .attr('transform', `translate(${wantedWidth},0)`)
                .call(d3.axisRight(rightYScale));

            // Add right y-axis label
            svg.append('text')
                .attr('transform', 'rotate(-90)')
                .attr('y', wantedWidth + margin.right - 5)
                .attr('x', 0 - (wantedHeight / 2))
                .attr('dy', '-1em')
                .style('text-anchor', 'middle')
                .text(rightSeries[0].name);
        }

        // Draw the lines
        dataSeries.forEach(series => {
            const yScale = series.yAxisPosition === 'right' ? rightYScale : leftYScale;
            const line = d3.line()
                .x(d => xScale(d[series.xKey]))
                .y(d => yScale(d[series.yKey]));

            svg.append('path')
                .datum(series.data)
                .attr('fill', 'none')
                .attr('stroke', series.color || 'steelblue')
                .attr('stroke-width', 1.5)
                .attr('d', line);
        });

        // Add legend
        const legend = svg.append('g')
            .attr('transform', `translate(${wantedWidth - 110}, 10)`);

        // Add background rectangle for the legend
        legend.append('rect')
            .attr('x', -10)
            .attr('y', -10)
            .attr('width', 110)
            .attr('height', dataSeries.length * 20 + 7)
            .attr('fill', 'white')
            .attr('stroke', 'black')
            .attr('opacity', 0.7)
            .attr('stroke-width', 1);

        dataSeries.forEach((series, index) => {
            const legendRow = legend.append('g')
                .attr('transform', `translate(0, ${index * 20})`);

            legendRow.append('rect')
                .attr('width', 10)
                .attr('height', 10)
                .attr('fill', series.color || 'steelblue');

            legendRow.append('text')
                .attr('x', 15)
                .attr('y', 10)
                .text(series.name || `Series ${index + 1}`);
        });

    }, [dataSeries, xScale, leftYScale, rightYScale, wantedHeight, wantedWidth, margin.left, margin.top, margin.right, margin.bottom, leftSeries.length, rightSeries.length]);

    return <div ref={chartRef}></div>;
}

import React, {createContext, useState} from "react";

export const SessionContext = createContext(null);

export const SessionContextProvider = ({children}) => {
    const [session, setSession] = useState(null);
    const [sessionData, setSessionData] = useState(null);
    const [loadingData, setLoadingData] = useState(false);
    const [loadingVideos, setLoadingVideos] = useState(false);
    const [hoveringX, setHoveringX] = useState(null);
    const [selectedId, setSelectedId] = useState(null);
    const [selectedPeriodStart, setSelectedPeriodStart] = useState(null);
    const [selectedPeriodEnd, setSelectedPeriodEnd] = useState(null);
    const [displayIncident, setDisplayIncident] = useState(false);

    const [annotations, setAnnotations] = useState([]);
    const [editAnnotation, setEditAnnotation] = useState(null);

    return <SessionContext.Provider value={{
        hoveringX,
        setHoveringX,

        selectedId,
        setSelectedId,

        session,
        setSession,

        sessionData,
        setSessionData,

        loadingData,
        setLoadingData,

        loadingVideos,
        setLoadingVideos,

        selectedPeriodStart,
        setSelectedPeriodStart,

        selectedPeriodEnd,
        setSelectedPeriodEnd,

        displayIncident,
        setDisplayIncident,

        annotations,
        setAnnotations,

        editAnnotation,
        setEditAnnotation,
    }}>
        {children}
    </SessionContext.Provider>
}

import {UserApi} from "../api_client/generated/User";

export const userService = {
    login,
    logout
}

function login(username, password) {
    return UserApi.authenticate.f(username, password).then(setAuthToken)
}

export function logout(reasonUnauthorized=false) {
    const isLoginUrl = window.location.toString().toLowerCase().indexOf('/login') !== -1

    if (!isLoginUrl) {
        if (reasonUnauthorized) {
            localStorage.setItem('redirAfterLogin', '/')
        } else {
            localStorage.setItem('redirAfterLogin', window.location.toString())
        }
    }

    localStorage.removeItem('user')
    localStorage.removeItem('token')
    localStorage.removeItem('tokenExpires')
}


export function setAuthToken({token, expires}) {
    localStorage.setItem('token', token)
    localStorage.setItem('tokenExpires', expires)
}

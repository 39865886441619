import React, {useState, useEffect, useContext} from 'react';
import {AnnotationApi} from "../../../api_client/generated/Annotation";
import Annotation from "./Annotation";
import './annotation-manager.css';
import {SessionContext} from "../SessionContextProvider";
import {getUTCDateAtFaceValue} from "../../../helpers";
import BasicPopupWindow from "../../../component/basic-popup-window/BasicPopupWindow";

export default function AnnotationManager({canCreateNew}) {
    const [newAnnotation, setNewAnnotation] = useState({
        title: '',
        description: '',
        start_timestamp: '',
        end_timestamp: ''
    });

    const {
        selectedPeriodStart, selectedPeriodEnd,
        setSelectedPeriodStart, setSelectedPeriodEnd,
        session,
        setDisplayIncident,
        annotations, setAnnotations,
        editAnnotation, setEditAnnotation
    } = useContext(SessionContext);

    useEffect(() => {
        if (selectedPeriodStart !== null) {
            // to format:  2024-10-22T10:02
            const val = getUTCDateAtFaceValue(selectedPeriodStart).toISOString().split('.')[0]
            setNewAnnotation(prev => ({...prev, start_timestamp: val}))
        }
        if (selectedPeriodEnd !== null) {
            const val = getUTCDateAtFaceValue(selectedPeriodEnd).toISOString().split('.')[0]
            setNewAnnotation(prev => ({...prev, end_timestamp: val}))
        }
    }, [selectedPeriodStart, selectedPeriodEnd]);

    // Fetch all annotations
    useEffect(() => {
        if (!session || Object.entries(session).length === 0) return;
        let session_start = new Date(session.session_start_timestamp)
        session_start.setHours(session_start.getHours() + 2)
        console.log('fetching annotations')
        let session_end = new Date(session.session_end_timestamp)
        session_end.setHours(session_end.getHours() + 2)
        AnnotationApi.list_annotations_by_time_period.f(
            session_start,
            session_end
        ).then(setAnnotations)
    }, [session]);

    // Create a new annotation
    const handleCreate = () => {
        AnnotationApi.create_annotation.f(
            newAnnotation.description,
            newAnnotation.start_timestamp,
            {
                title: newAnnotation.title === '' ? null : newAnnotation.title,
                end_timestamp: newAnnotation.end_timestamp === '' ? null : newAnnotation.end_timestamp
            }
        ).then(created => {
            setAnnotations(prev => [...prev, created]);
            setNewAnnotation(prev => ({
                ...prev,
                title: '',
                description: '',
            }));
        })
    };

    // Update an annotation
    const handleUpdate = () => {
        AnnotationApi.update_annotation.f(
            editAnnotation.id,
            {
                title: editAnnotation.title,
                description: editAnnotation.description,
                start_timestamp: editAnnotation.start_timestamp,
                end_timestamp: editAnnotation.end_timestamp
            }
        ).then(updated => {
            setAnnotations(annotations.map(a => (a.id === updated.id ? updated : a)));
            setEditAnnotation(null);
        })
    };

    return <>
        {editAnnotation && <BasicPopupWindow className='edit-annotation-popup' showPopup={editAnnotation !== null}
                                             closePopup={_ => setEditAnnotation(null)}>
            <main>
                <h2>Edit Annotation</h2>
                <label>
                    Title:
                    <input
                        type="text"
                        value={editAnnotation.title}
                        onChange={e => setEditAnnotation({...editAnnotation, title: e.target.value})}
                    />
                </label>

                <label>
                    Description:
                    <textarea
                        value={editAnnotation.description}
                        onChange={e => setEditAnnotation({...editAnnotation, description: e.target.value})}
                    />
                </label>

                <label>
                    Start Timestamp:
                    <input
                        type="datetime-local"
                        value={editAnnotation.start_timestamp}
                        onChange={e => setEditAnnotation({...editAnnotation, start_timestamp: e.target.value})}
                    />
                </label>

                <label>
                    End Timestamp:
                    <input
                        type="datetime-local"
                        value={editAnnotation.end_timestamp}
                        onChange={e => setEditAnnotation({...editAnnotation, end_timestamp: e.target.value})}
                    />
                </label>
                <button onClick={handleUpdate}>Update</button>
            </main>
        </BasicPopupWindow>}
        <div className='annotation-manager'>
            {canCreateNew && <div className='create-new-wrapper'>
                <h5>New Annotation</h5>
                <input
                    type="text"
                    placeholder="Title"
                    value={newAnnotation.title}
                    onChange={e => setNewAnnotation({...newAnnotation, title: e.target.value})}
                />
                <input
                    type="text"
                    placeholder="Description"
                    value={newAnnotation.description}
                    onChange={e => setNewAnnotation({...newAnnotation, description: e.target.value})}
                />
                <input
                    type="datetime-local"
                    placeholder="Start Time"
                    value={newAnnotation.start_timestamp}
                    onChange={e => {
                        setNewAnnotation({...newAnnotation, start_timestamp: e.target.value})
                        setSelectedPeriodStart(new Date(e.target.value))
                    }}
                />
                <input
                    type="datetime-local"
                    placeholder="End Time"
                    value={newAnnotation.end_timestamp}
                    onChange={e => {
                        setNewAnnotation({...newAnnotation, end_timestamp: e.target.value})

                        const d = new Date(e.target.value)
                        setSelectedPeriodEnd(d)
                    }}
                />
                <button onClick={handleCreate}>Create</button>
            </div>}
            {annotations.map((annotation, idx) => <div key={annotation.id}>
                <Annotation annotation={annotation} withMap idx={idx + 1}/>
                {canCreateNew && <div className='btn-wrapper'>
                    <button onClick={() => {
                        setSelectedPeriodStart(new Date(annotation.start_timestamp))
                        setSelectedPeriodEnd(new Date(annotation.end_timestamp))
                        setDisplayIncident(true)
                    }}>Select
                    </button>
                </div>}
            </div>)}
        </div>
    </>;
};

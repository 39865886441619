import React, {useCallback, useEffect, useMemo, useState} from "react";
import {SensorReadingApi} from "../../api_client/generated/SensorReading";
import {VideoApi} from "../../api_client/generated/Video";
import ProteusMap from "../../component/proteus-map/ProteusMap";

export default function DashboardPage() {
    const [sensorData, setSensorData] = useState(null);

    const [latestImages, setLatestImages] = useState({});

    const fetchData = useCallback(() => {
        SensorReadingApi.most_recent_data.f({}).then(ds => {
            setSensorData(ds);
            // console.log('Got sensor data', ds)
        });
        // VideoApi.fetch_latest_image.f('mast').then(im => setLatestImages(prev => ({
        //     ...prev,
        //     mast: im
        // })));
        // VideoApi.fetch_latest_image.f('keel').then(im => setLatestImages(prev => ({
        //     ...prev,
        //     keel: im
        // })));
    }, []);

    const latestSensorData = useMemo(() => {
        if (!sensorData) return null;
        return sensorData[0]
    }, [sensorData]);

    useEffect(() => {
        fetchData()
        // Fetch data every 5 seconds
        const intervalId = setInterval(() => {
            fetchData()
        }, 5000);

        return () => clearInterval(intervalId);
    }, [fetchData]);

    return <div className='home-page'>
        <main>
            <div className='metrics'>
                <ProteusMap sensorData={sensorData}
                            indicatorName={'tryptophan'}
                            staticIndicatorMax={30}
                            staticIndicatorMin={22}
                            relativeColors
                            allow_pan={false}
                            forceShiftkeyToZoom
                            displayKeys={[ 'tryptophan', 'salinity', 'conductivity', 'temperature', 'turbidity', 'ph', 'orp', 'bod', 'cdom']}
                            selectedDatapoint={null}/>
                {latestSensorData && Object
                    .entries(latestSensorData.properties)
                    .filter(([key, value]) => key === 'timestamp')
                    .map(([key, value]) =>
                        <h2 className='value'>{value}</h2>
                    )}
                {latestSensorData && Object
                    .entries(latestSensorData.properties)
                    .filter(([key, value]) => ['time', 'date', 'latitude', 'longitude', 'unknown_3rd_parameter', 'ph_mV', 'cable_power'].includes(key) === false)
                    .map(([key, value]) =>
                        <div className='metric'>
                            <p className='title'>{key}</p>
                            <h2 className='value'>{value}</h2>
                        </div>
                    )}
            </div>
        </main>
    </div>
}

import {http} from "../api";

const easyUnpack = ({data}) => data;
export const AnnotationApi = {
    create_annotation: {
        f: function create_annotation(description, start_timestamp, {title = null, end_timestamp = null}) {
            let url = '/annotation';
            return http.post(url, {description, start_timestamp, title, end_timestamp}).then(easyUnpack)
        }
    },
    list_annotations: {
        f: function list_annotations() {
            let url = '/annotation';
            return http.get(url, {params: {}}).then(easyUnpack)
        }
    },
    get_annotation: {
        f: function get_annotation(annotation_id) {
            let url = '/annotation/$annotation_id';
            let parameterNames = ["$annotation_id"];
            [annotation_id].forEach((parameterValue, index) => {
                url = url.replace(parameterNames[index], parameterValue)
            })
            return http.get(url, {params: {}}).then(easyUnpack)
        }
    },
    update_annotation: {
        f: function update_annotation(annotation_id, {
            title = null,
            description = null,
            start_timestamp = null,
            end_timestamp = null
        }) {
            let url = '/annotation/$annotation_id';
            let parameterNames = ["$annotation_id"];
            [annotation_id].forEach((parameterValue, index) => {
                url = url.replace(parameterNames[index], parameterValue)
            })
            return http.patch(url, {title, description, start_timestamp, end_timestamp}).then(easyUnpack)
        }
    },
    list_annotations_by_time_period: {
        f: function list_annotations_by_time_period(start_timestamp, end_timestamp) {
            let url = '/annotation/by-time-period';
            return http.get(url, {params: {start_timestamp, end_timestamp}}).then(easyUnpack)
        }
    },
    delete_annotation: {
        f: function delete_annotation(annotation_id) {
            let url = '/annotation/$annotation_id';
            let parameterNames = ["$annotation_id"];
            [annotation_id].forEach((parameterValue, index) => {
                url = url.replace(parameterNames[index], parameterValue)
            })
            return http.delete(url, {data: {}}).then(easyUnpack)
        }
    },
};

import React, {useCallback, useEffect, useMemo, useState} from "react";
import {useLocation, useNavigate} from "react-router-dom";
import DataFilters from "./data-filters/DataFilters";
import SortableTable from "../../../component/sortable-table/SortableTable";
import {SensorReadingApi} from "../../../api_client/generated/SensorReading";
import {DataSessionApi} from "../../../api_client/generated/DataSession";
import './data-export-page.css';
import BasicPopupWindow from "../../../component/basic-popup-window/BasicPopupWindow";
import SimpleButton from "../../../component/simple-button/SimpleButton";
import InputField from "../../../component/input-field/InputField";


export default function DataExportPage() {
    const [sessionData, setSessionData] = useState(null);
    const [dataFetchedByFilters, setDataFetchedByFilters] = useState(null);
    const [dataSessions, setDataSessions] = useState([]);
    const [loadingData, setLoadingData] = useState(false);
    const [showExportModal, setShowExportModal] = useState(false);
    const [qaNiveau, setQaNiveau] = useState('');

    const filterfunctions = useMemo(() => ({
        in_water: (r) => r.properties.conductivity > 100
    }), []);

    useEffect(() => {
        DataSessionApi.list_sessions.f().then(setDataSessions)
    }, []);

    const [keys, setKeys] = useState([
        'timestamp',
        'time',
        'temperature',
        'tryptophan',
        'turbidity',
        'cdom',
        'orp',
        'ph',
        'salinity',
        'latitude',
        'longitude',
    ]);

    if (sessionData && sessionData.length > 0) {
        console.log('Session data', sessionData[0])
    }

    const location = useLocation();
    const navigate = useNavigate();

    function parseBoolQuery(query, key) {
        let val = query.get(key)
        val = val === null || val === 'true'
        return val
    }

    const filters = useMemo(() => {
        const query = new URLSearchParams(location.search);
        let page_size = query.get('page_size') || 100
        let page_nr = query.get('page_nr') || 0
        let data_session_id = query.get('data_session_id') || null
        let in_water = parseBoolQuery(query, 'in_water')

        let timestamp_from = query.get('timestamp_from') || ''
        let timestamp_to = query.get('timestamp_to') || ''

        if (page_size !== null) page_size = parseInt(page_size)
        if (page_nr !== null) page_nr = parseInt(page_nr)
        if (data_session_id !== null && data_session_id !== 'all') data_session_id = parseInt(data_session_id)

        return {
            page_size: page_size,
            page_nr: page_nr,
            data_session_id: data_session_id,
            in_water: in_water,
            timestamp_from,
            timestamp_to,
        }
    }, [location.search]);

    const updateFilter = useCallback((filter_name, filter_value) => {
        const updatedFilters = {...filters, [filter_name]: filter_value};
        const q = Object.entries(updatedFilters).reduce((acc, [key, value]) => {
            acc.push(`${key}=${value}`);
            return acc;
        }, []).join('&');
        navigate({
            search: `?${q}`
        });
    }, [navigate, filters]);

    const selectedSession = useMemo(() => {
        if (!filters.data_session_id || !dataSessions) return null;
        if (filters.data_session_id === 'all') return 'all';
        return dataSessions.find(ds => ds.id === filters.data_session_id);
    }, [filters.data_session_id, dataSessions]);

    const fetchData = useCallback(() => {
        if (!dataSessions || loadingData) return;

        if (dataFetchedByFilters !== null) {
            let alreadyFetchedForTheseFilters = true;
            Object.entries(dataFetchedByFilters).forEach(([key, value]) => {
                if (filters[key] !== value) {
                    alreadyFetchedForTheseFilters = false;
                }
            })
            if (alreadyFetchedForTheseFilters) return;
        }
        const offset = filters.page_nr * filters.page_size;
        const limit = filters.page_size;

        let session_start = null;
        let session_end = null;
        if (selectedSession === 'all') {
            dataSessions.forEach(ds => {
                if (session_start === null || ds.session_start_timestamp < session_start) session_start = ds.session_start_timestamp;
                if (session_end === null || ds.session_end_timestamp > session_end) session_end = ds.session_end_timestamp;
            })
        } else if (filters.timestamp_from && filters.timestamp_to) {
            session_start = filters.timestamp_from + ':00Z';
            session_end = filters.timestamp_to + ':00Z';
        } else if (selectedSession) {
            session_start = selectedSession.session_start_timestamp;
            session_end = selectedSession.session_end_timestamp;
        } else {
            console.log('No session or timestamp selected', filters)
            return;
        }

        console.log('List sensor data', {
            loadingData,
            dataFetchedByFilters,
            dataSessions,
            filters,
            selectedSession,
        })


        setLoadingData(true);
        setDataFetchedByFilters(filters)

        SensorReadingApi.list_sensor_data.f({
            timestamp_from: session_start,
            timestamp_to: session_end,
            offset,
            limit,
            only_in_water: filters.in_water
        }).then(ds => {
            setSessionData(ds.sort((a, b) => new Date(a.properties.timestamp) - new Date(b.properties.timestamp)))
            setLoadingData(false);
        });
    }, [selectedSession, filters, dataSessions, loadingData, dataFetchedByFilters]);

    const filteredSessionData = useMemo(() => {
        const activeFilters = Object.entries(filterfunctions).filter(([key, filter]) => filters[key]);
        return sessionData?.filter(r => {
            return activeFilters.every(([key, filter]) => {
                if (!filter) return true;
                return filter(r)
            })
        })
    }, [sessionData, filters, filterfunctions]);

    return (<div className='data-export-page'>
        <BasicPopupWindow showPopup={showExportModal} setShowPopup={setShowExportModal}
                          closePopup={setShowExportModal} className='export-modal'>
            <h2>Export</h2>
            <p>Export data to CSV</p>

            <InputField title='QANiveau' value={qaNiveau} onChanged={setQaNiveau}/>

            <SimpleButton value='Export' onClick={_ => {
                let csv = [...keys, 'QANiveau'].join('\t') + '\n'
                csv += filteredSessionData.map(d => keys.map(k => d.properties[k]).join('\t') + `\t${qaNiveau}`).join('\n')
                const blob = new Blob([csv], {type: 'text/csv'})
                const url = URL.createObjectURL(blob)
                const a = document.createElement('a')
                a.href = url
                a.download = 'export.csv'
                a.click()
                URL.revokeObjectURL(url)
            }}/>
        </BasicPopupWindow>
        <DataFilters initialFilters={filters}
                     updateFilter={updateFilter}
                     dataSessions={dataSessions}
                     keys={keys}
                     setKeys={setKeys}>
            <SimpleButton value='Fetch data' onClick={fetchData}/>
            <SimpleButton value='Export...' onClick={_ => setShowExportModal(true)}/>
        </DataFilters>
        {loadingData && <p>Loading data...</p>}
        {filteredSessionData && <SortableTable
            items={filteredSessionData.map(d => ({...d.properties, id: d.id}))}
            fields={keys.map(k => ({key: k, title: k}))}
            customRenderFunction={(item, idx) => {
                return (<tr key={item.id || idx} id={`row-${item.id}`}>
                    {keys.map(key => <td key={key}>{item[key]}</td>)}
                </tr>)
            }}
        />}
    </div>)
}

import {useState, useEffect, useCallback, useMemo, useContext} from "react";
import {SensorReadingApi} from "../../api_client/generated/SensorReading";
import {SessionContext} from "./SessionContextProvider";
import {truncateLatLong} from "../../helpers";

export function useSessionData(filters = null) {
    const {
        selectedPeriodStart, selectedPeriodEnd,
        displayIncident,
        session,
        sessionData, setSessionData,
        loadingData, setLoadingData,
    } = useContext(SessionContext);

    const [fetchedDataFromSessSettings, setFetchedDataFromSessSettings] = useState({
        session_start_timestamp: null,
        session_end_timestamp: null,
    });

    // Fetch the session data when the session is available
    const fetchData = useCallback(() => {
        if (!session || !session.session_end_timestamp) return;
        if (sessionData !== null) return;
        if (loadingData) return;
        if (fetchedDataFromSessSettings.session_start_timestamp === session.session_start_timestamp
            && fetchedDataFromSessSettings.session_end_timestamp === session.session_end_timestamp)
            return;

        console.log('Fetching sensor data!');

        setLoadingData(true);

        SensorReadingApi.list_sensor_data.f({
            timestamp_from: session.session_start_timestamp,
            timestamp_to: session.session_end_timestamp,
            only_in_water: false,
        })
            .then((ds) => {
                setLoadingData(false);
                setFetchedDataFromSessSettings({
                    session_start_timestamp: session.session_start_timestamp,
                    session_end_timestamp: session.session_end_timestamp,
                });
                const data = ds.map((d) => ({
                    ...d, properties: {
                        ...d.properties,
                        id: d.id,
                        timestamp: new Date(d.properties.timestamp),
                        timestamp_iso: new Date(d.properties.timestamp).toISOString(),
                        latitude: truncateLatLong(d.properties.latitude),
                        longitude: truncateLatLong(d.properties.longitude),
                    },
                }));
                setSessionData(data.sort((a, b) => new Date(a.properties.timestamp) - new Date(b.properties.timestamp)));
            });
    }, [session, sessionData, loadingData, fetchedDataFromSessSettings.session_start_timestamp, fetchedDataFromSessSettings.session_end_timestamp, setLoadingData, setSessionData]);

    useEffect(() => {
        if (session && session.session_end_timestamp) {
            fetchData();
        }
    }, [session, fetchData]);

    // Compute the filtered session data
    const filteredSessionData = useMemo(() => {
        if (!sessionData) return null;
        let res = sessionData;

        if (filters !== null) {
            res = res
                .filter((r) => Object.entries(filters).every(([key, filter]) => {
                    if (!filter) return true;
                    return filter(r);
                }));
        }

        if (!res) return null;

        if (displayIncident && selectedPeriodStart !== null && selectedPeriodEnd !== null) {
            const f = Math.min(selectedPeriodStart, selectedPeriodEnd);
            const t = Math.max(selectedPeriodStart, selectedPeriodEnd);
            res = res.filter((r) => new Date(r.properties.timestamp) >= f && new Date(r.properties.timestamp) <= t);
        }

        return res
            .sort((a, b) => new Date(a.properties.timestamp) - new Date(b.properties.timestamp));
    }, [sessionData, filters, selectedPeriodStart, selectedPeriodEnd, displayIncident,]);

    return {
        sessionData,
        filteredSessionData,
        loadingData,
        setSessionData,
        setLoadingData,
        fetchData,
    };
}

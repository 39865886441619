import React from "react";
import BasicPopupWindow from "../../component/basic-popup-window/BasicPopupWindow";
import InputField from "../../component/input-field/InputField";
import SimpleButton from "../../component/simple-button/SimpleButton";
import {DataSessionApi} from "../../api_client/generated/DataSession";

export default function EditSessionPoup({sessionEdit, setSessionEdit, sessionId, setSession}) {
    return (
        <BasicPopupWindow className='edit-session-popup' showPopup={sessionEdit !== null}
                          closePopup={_ => setSessionEdit(null)}>
            {sessionEdit && <>
                <h2>Edit session</h2>
                <InputField title='Session name'
                            value={sessionEdit.name}
                            onChanged={val => {
                                setSessionEdit(p => ({...p, name: val}))
                            }}/>
                <InputField title='Session start'
                            value={sessionEdit.session_start_timestamp}
                            type='datetime-local'
                            onChanged={val => {
                                setSessionEdit(p => ({...p, session_start_timestamp: val}))
                            }}/>
                <InputField title='Session end'
                            value={sessionEdit.session_end_timestamp}
                            type='datetime-local'
                            onChanged={val => {
                                setSessionEdit(p => ({...p, session_end_timestamp: val}))
                            }}/>
                <SimpleButton value='Save' onClick={_ => {
                    console.log('updating session', sessionEdit)
                    const offset = new Date().getTimezoneOffset()

                    let timestamp_start = new Date(sessionEdit.session_start_timestamp)
                    timestamp_start = new Date(timestamp_start.setMinutes(timestamp_start.getMinutes() + (offset*-1)))

                    let timestamp_end = new Date(sessionEdit.session_end_timestamp)
                    timestamp_end = new Date(timestamp_end.setMinutes(timestamp_start.getMinutes() + (offset*-1)))

                    DataSessionApi.update_session.f(sessionId, {
                        name: sessionEdit.name,
                        timestamp_end,
                        timestamp_start,
                    }).then(() => {
                        DataSessionApi.get_session.f(sessionId).then(setSession);
                        setSessionEdit(null);
                    })
                }}/>
            </>}
        </BasicPopupWindow>)
}
import React, {useCallback, useContext, useEffect, useMemo, useRef, useState} from 'react';
import * as d3 from 'd3';
import {SessionContext} from "../../page/session/SessionContextProvider";
import './line-chart.css';

export default function LineChart({
                                      id,
                                      all_data,
                                      data_key,
                                      height = 400,
                                      width = 800,
                                      mean = null,
                                      std_deviation = null,
                                  }) {
    const chartRef = useRef();
    const [initialized, setInitialized] = useState(false);

    const [hoveringIndex, setHoveringIndex] = useState(null);

    const {
        selectedId,

        hoveringX,
        setHoveringX,
        setSelectedId,

        selectedPeriodStart,
        setSelectedPeriodStart,
        selectedPeriodEnd,
        setSelectedPeriodEnd,
        displayIncident,
    } = useContext(SessionContext);

    const domain = useMemo(() => d3
        .scaleTime()
        .domain(d3.extent(all_data, (d) => d.properties.timestamp))
        .range([0, width - 70]), [all_data, width]);

    const bisect = useMemo(() => d3.bisector((d) => d.properties.timestamp).left, []);
    const getIndex = useCallback((x0) => bisect(all_data, x0, 1), [all_data, bisect]);

    const {
        margin,
        wantedHeight,
        wantedWidth,
        y
    } = useMemo(() => {
        const margin = {top: 10, right: 30, bottom: 30, left: 40};
        const wantedWidth = width - margin.left - margin.right;
        const wantedHeight = height - margin.top - margin.bottom;

        let minY = d3.min(all_data, (d) => d.properties[data_key]);
        let maxY = d3.max(all_data, (d) => d.properties[data_key]);

        if (mean !== null && std_deviation !== null) {
            if (mean + std_deviation > maxY) {
                maxY = mean + std_deviation;
            }
        }

        const y = d3
            .scaleLinear()
            .domain([minY * 0.98, maxY * 1.02])
            .range([wantedHeight, 0]);

        return {
            margin,
            wantedHeight,
            wantedWidth,
            y,
        }
    }, [all_data, data_key, height, mean, std_deviation, width]);


    useEffect(() => {
        if (hoveringIndex === null) return;
        let idx = hoveringIndex;
        if (idx < 0 || idx >= all_data.length) return;
        const d0 = all_data[hoveringIndex];
        setHoveringX(d0);
    }, [hoveringIndex, all_data, setHoveringX, selectedPeriodStart, selectedPeriodEnd]);

    useEffect(() => {
        if (!domain) return;
        // Set dimensions and margins for the chart

        // Remove any previous content inside the chart container
        d3.select(chartRef.current).selectChildren().remove();

        // Create the SVG element
        const svg = d3
            .select(chartRef.current)
            .append('svg')
            .attr('width', wantedWidth + margin.left + margin.right)
            .attr('height', wantedHeight + margin.top + margin.bottom)
            .append('g')
            .attr('transform', `translate(${margin.left},${margin.top})`);

        // Add the X axis
        svg
            .append('g')
            .attr('transform', `translate(0,${wantedHeight + 10})`)
            .call(d3.axisBottom(domain).tickFormat(d3.timeFormat('%H:%M')))

        // Add the Y axis
        svg.append('g').call(d3.axisLeft(y));

        // Add the line
        svg
            .append('path')
            .attr('id', `line-${id}`)
            .attr('fill', 'none')
            .attr('stroke', 'steelblue')
            .attr('stroke-width', 1.5)

        // Text top left that displays y value
        svg
            .append('text')
            .attr('id', `y-value-text-${id}`)
            .attr('x', 4)
            .attr('y', 0)
            .attr('dy', '1em')
            .attr('fill', 'black')
            .attr('font-size', '12px')
            .text(data_key);

        // text bottom right for the x value
        svg
            .append('text')
            .attr('id', `x-value-text-${id}`)
            .attr('x', wantedWidth - 30)
            .attr('y', wantedHeight + 10)
            .attr('dy', '-0.5em')
            .attr('fill', 'black')
            .attr('font-size', '12px')
            .text('Time');

        // Add the vertical line for hovering
        const selectedLine = svg
            .append('line')
            .attr('class', 'hover-line')
            .attr('id', `selected-line-${id}`)
            .attr('y1', 0)
            .attr('y2', wantedHeight)
            .attr('stroke', 'red')
            .attr('stroke-width', 1)
            .style('opacity', 0);  // Initially hidden

        // Add the vertical line for hovering
        const hoverLine = svg
            .append('line')
            .attr('class', 'hover-line')
            .attr('id', `hover-line-${id}`)
            .attr('y1', 0)
            .attr('y2', wantedHeight)
            .attr('stroke', 'black')
            .attr('stroke-width', 1)
            .style('opacity', 0);  // Initially hidden

        // Add the area for displaying selected incident
        svg
            .append('rect')
            .attr('id', `incident-area-${id}`)
            .attr('fill', 'green')
            .attr('pointer-events', 'none')
            .attr('height', wantedHeight)
            .style('opacity', 0.2)
            .attr('x', domain(selectedPeriodStart))
            .attr('width', Math.abs(domain(selectedPeriodEnd) - domain(selectedPeriodStart)))

        setInitialized(true);
    }, [data_key, domain, id, margin.bottom, margin.left, margin.right, margin.top, selectedPeriodEnd, selectedPeriodStart, wantedHeight, wantedWidth, y]);

    useEffect(() => {
        if (!initialized) return;

        const svg = d3.select(chartRef.current).select('svg').select('g');

        svg.select(`#line-${id}`)
            .datum(all_data)
            .attr('d', d3
                .line()
                .x((d) => domain(d.properties.timestamp))
                .y((d) => y(d.properties[data_key])));

        console.log('wanted width', wantedWidth, 'wanted height', wantedHeight);

        svg
            .append('rect')
            .attr('width', wantedWidth)
            .attr('height', wantedHeight)
            .attr('fill', 'none')
            .attr('pointer-events', 'all')
            .on('mousemove', function (event) {
                const x0 = domain.invert(d3.pointer(event, this)[0]);

                // find closest data point
                const i = getIndex(x0) - 1;
                setHoveringIndex(i);

                if (event.buttons === 1) {
                    setSelectedPeriodStart(prev => prev === null ? all_data[i].properties.timestamp : prev);
                    setSelectedPeriodEnd(all_data[i].properties.timestamp);
                }
            })
            .on('click', (event) => {
                const x0 = domain.invert(d3.pointer(event, this)[0]);

                // find closest data point
                const bisect = d3.bisector((d) => d.properties.timestamp).left;
                const i = bisect(all_data, x0, 1);
                const d0 = all_data[i - 1];
                if (i === all_data.length) {
                    setSelectedId(all_data[i - 1])
                    return;
                }
                const d1 = all_data[i];

                const dist0 = Math.abs(x0 - d0.properties.timestamp);
                const dist1 = Math.abs(x0 - d1.properties.timestamp);

                const d = dist0 > dist1 ? d1 : d0;
                setSelectedId(d);
            })
    }, [initialized, domain, getIndex, all_data, setSelectedId, setSelectedPeriodStart, setSelectedPeriodEnd, wantedWidth, wantedHeight, id, y, data_key]);

    useEffect(() => {
        if (!domain || !hoveringX) return;
        // Update the vertical line position based on hoveringX
        d3.select(`#hover-line-${id}`)
            .attr('x1', domain(hoveringX?.properties.timestamp))
            .attr('x2', domain(hoveringX?.properties.timestamp))
            .style('opacity', 1);

        d3.select(`#y-value-text-${id}`)
            .text(hoveringX?.properties[data_key]);

        let hours = String(hoveringX?.properties.timestamp.getHours()).padStart(2, '0');
        let minutes = String(hoveringX?.properties.timestamp.getMinutes()).padStart(2, '0');
        let formattedTime = `${hours}:${minutes}`;
        d3.select(`#x-value-text-${id}`)
            .text(formattedTime);
    }, [hoveringX, domain, id, data_key]);

    useEffect(() => {
        if (!domain || !selectedPeriodStart || !selectedPeriodEnd) return;
        if (displayIncident) return;
        d3.select(`#incident-area-${id}`)
            .attr('x', domain(selectedPeriodStart))
            .attr('width', Math.abs(domain(selectedPeriodEnd) - domain(selectedPeriodStart)))
            .attr('pointer-events', 'none')
            .style('opacity', 0.2);
    }, [domain, selectedPeriodStart, selectedPeriodEnd, id, displayIncident]);

    useEffect(() => {
        if (!domain) return;

        d3.select(`#selected-line-${id}`)
            .style('opacity', selectedId ? 1 : 0)
            .attr('x1', domain(selectedId?.properties.timestamp))
            .attr('x2', domain(selectedId?.properties.timestamp))
    }, [selectedId, domain, id]);

    return <div className='line-chart' ref={chartRef}></div>;
};

export function getUTCDateAtFaceValue(date) {
    let val = new Date(date)
    const offset = new Date().getTimezoneOffset()
    val = val.setMinutes(val.getMinutes() + (offset*-1))
    return new Date(val)
}

export function prettyTimestampFromDate(date) {
    return date.toLocaleTimeString('en-US', {timeZone: 'UTC', hour12: false})
}

export function truncateLatLong(val) {
    return Math.trunc(val * 1e8) / 1e8;
}

import React, {useContext, useMemo} from "react";
import ProteusMap from "../../../component/proteus-map/ProteusMap";
import {useSessionData} from "../useSessionData";
import MultiLineChart from "../../../component/plots/MultiLineChart";
import {truncateLatLong} from "../../../helpers";
import './annotation.css'
import {SessionContext} from "../SessionContextProvider";
import {AnnotationApi} from "../../../api_client/generated/Annotation";

export default function Annotation({session_id, annotation, withMap, idx = null}) {
    const {
        sessionData,
    } = useSessionData(session_id);

    const {
        setEditAnnotation,
        setAnnotations,
    } = useContext(SessionContext);

    const sameDate = useMemo(() => {
        const start = new Date(annotation.start_timestamp);
        const end = annotation.end_timestamp ? new Date(annotation.end_timestamp) : null;
        return end ? start.toDateString() === end.toDateString() : false;
    }, [annotation]);

    const fromToString = useMemo(() => {
        if (!annotation.end_timestamp) {
            return new Date(annotation.start_timestamp).toLocaleTimeString(('en-GB'));
        }
        if (sameDate) {
            return `${new Date(annotation.start_timestamp).toLocaleTimeString(('en-GB'))} - ${new Date(annotation.end_timestamp).toLocaleTimeString('en-GB')}`;
        }
        return `${new Date(annotation.start_timestamp).toLocaleTimeString(('en-GB'))} - ${new Date(annotation.end_timestamp).toLocaleTimeString('en-GB')}`;
    }, [annotation, sameDate]);

    const filteredData = useMemo(() => {
        if (!sessionData) return null;
        if (!annotation.end_timestamp) {
            return null;
        }
        return sessionData
            .filter(d => d.properties.timestamp >= new Date(annotation.start_timestamp))
            .filter(d => d.properties.timestamp <= new Date(annotation.end_timestamp));
    }, [annotation, sessionData]);

    const avgPosition = useMemo(() => {
        if (!filteredData) return null;
        const lat = filteredData.reduce((a, b) => a + b.properties.latitude, 0) / filteredData.length;
        const lon = filteredData.reduce((a, b) => a + b.properties.longitude, 0) / filteredData.length;
        return `(${truncateLatLong(lat)}, ${truncateLatLong(lon)})`
    }, [filteredData]);

    // Delete an annotation
    const handleDelete = (id) => {
        if (window.confirm('Are you sure you want to delete this annotation?')) {
            AnnotationApi.delete_annotation.f(id).then(() => setAnnotations(prev => prev.filter(a => a.id !== id)))
        }
    };

    return <div key={annotation.id} className='annotation'>
        <div className='title-wrapper'>
            <h2>{idx !== null ? `${idx}. ` : ''}{annotation.title}</h2>
            <button onClick={() => setEditAnnotation(annotation)}>Edit</button>
            <button onClick={() => handleDelete(annotation.id)}>Delete</button>
        </div>
        <sub>{fromToString}</sub><sub> {avgPosition}</sub>
        <p>{annotation.description}</p>
        {withMap && filteredData && filteredData.length > 0 && <>
            <ProteusMap
                sensorData={filteredData}
                height={'250px'}
                allow_pan={false}
                forceShiftkeyToZoom
                displayKeys={['tryptophan', 'salinity']}
                displaySettings={false}
                selectedDatapoint={null}
                setSelectedDatapoint={null}
            />
            <br/>
            <div className='graph-overflow-wrapper'>
                <MultiLineChart
                    id={`annotation-${annotation.id}-mult`}
                    dataSeries={[{
                        name: 'Tryptophan',
                        color: 'red',
                        data: filteredData.map(d => ({
                            value: d.properties.tryptophan,
                            timestamp: new Date(d.properties.timestamp)
                        })),
                        xKey: 'timestamp',
                        yKey: 'value',
                        yAxisPosition: 'left'
                    }, {
                        name: 'Salinity',
                        color: 'blue',
                        data: filteredData.map(d => ({
                            value: d.properties.salinity,
                            timestamp: new Date(d.properties.timestamp)
                        })),
                        xKey: 'timestamp',
                        yKey: 'value',
                        yAxisPosition: 'right'
                    }]}
                    width={780}
                    height={200}
                />
            </div>
        </>}
    </div>
}

import React, {useState} from "react";
import './header.css';
import cross from '../../assets/clear-24px.svg';
import burgerMenuIcon from '../../assets/burger-menu-icon.png';
import {useNavigate} from "react-router-dom";
import logo from '../../assets/logo/logo solo colour.png';
import logoText from '../../assets/logo/name solo black.png';

export default function Header() {
    const [menuOpen, setMenuOpen] = useState(false);
    const menuItems = [
        {
            title: 'Live View',
            url: '/dashboard'
        },
        {
            title: 'Sessions',
            url: '/session'
        },
        {
            title: 'Water profiles',
            url: '/water-profile'
        },
        {
            title: 'Logout',
            url: '/logout'
        }
    ]

    return <>
        <div className='header'>
            <a href='/' className='header-title'>
                <img src={logoText} alt='logo text' className='logo-text'/>
                <img src={logo} alt='logo' className='logo'/>
            </a>
            <div className='spacer'>&nbsp;</div>
            <img src={menuOpen ? cross : burgerMenuIcon}
                 alt='open or close menu'
                 className={`burger-menu-icon ${menuOpen ? 'isopen' : ''}`}
                 onClick={() => setMenuOpen(p => !p)}/>
            <div className='inline-menu'>
                {menuItems.map((item, index) =>
                    <a key={index} href={item.url} className='menu-item'>{item.title}</a>
                )}
            </div>
        </div>
        {menuOpen && <div className='menu-overlay' onClick={() => setMenuOpen(false)}>
            <div className='mobile-menu'>
                {menuItems.map((item, index) =>
                    <a key={index} href={item.url} className='menu-item'>{item.title}</a>
                )}
            </div>
        </div>}
    </>
}

import React, {useCallback, useEffect, useState} from 'react'
import './login-page.css'
import InputField from "../../component/input-field/InputField";
import SimpleButton from "../../component/simple-button/SimpleButton";
import {useNavigate} from 'react-router-dom';
import {userService} from "../../auth/user-service";

export default function LoginPage() {
    const [user, setUser] = useState('')
    const [password, setPassword] = useState('')
    const [errorMsg, setErrorMsg] = useState('')
    const [loading, setLoading] = useState(false);
    const [isLoggedIn, setIsLoggedIn] = useState(undefined);

    const navigate = useNavigate()

    useEffect(() => {
        if (!isLoggedIn) return;

        const redir_path = localStorage.getItem('redirAfterLogin')
        if (redir_path && redir_path !== '/login' && redir_path !== '/logout') {
            localStorage.removeItem('redirAfterLogin')
            window.location = redir_path
        } else {
            navigate('/')
        }
    }, [navigate, isLoggedIn]);

    const submit = useCallback(() => {
        setLoading(true);
        userService.login(user, password)
            .then(r => {
                setLoading(false);
                setIsLoggedIn(true)
            }).catch(e => {
            console.log(e);
            setLoading(false);
            setErrorMsg('Wrong password or username')
        })
    }, [user, password]);


    return (<div className='login-page'>
        <div className='wrapper'>
            <h1>Welcome to <u>Sensemakers</u></h1>
            <h4>What's the ocean up to?</h4>
            <div className='spacer'>&nbsp;</div>
            <InputField
                placeholder='Username'
                value={user}
                onChanged={setUser}
                displayClearIcon
                submit={submit}
                error={errorMsg && errorMsg}
            />
            <InputField
                placeholder='Enter password'
                value={password}
                onChanged={setPassword}
                type='password'
                displayClearIcon
                submit={submit}
            />
            <div className='spacer'>&nbsp;</div>
            <SimpleButton disabled={loading} value='LOGIN' onClick={submit} loading={loading}/>
        </div>
    </div>)
}
